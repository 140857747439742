export const FilterKeys = {
    allClubs: 'allClubsV2',
    pendingClubs: 'pendingClubsV1',
    allTeamPlayers: 'allTeamPlayers',
    allCompetitions: 'allCompetitions',
    newPlayersBase: 'newPlayersBase',
    renewDlPlayers: 'renewDlPlayers',
    allTeams: 'allTeams',
    availableTeamPlayers: 'availableTeamPlayers',
    allNotifications: 'allNotifications',
    freePlayersBase: 'freePlayersBase',
    allPlayersBase: 'allPlayersBase',
    renewOtherPlayersBase: 'renewOtherPlayersBase',
    activePlayersBase: 'activePlayersBaseV2',
    allTeamsTable: 'allTeamsTable',
    inactivePlayersTable: 'inactivePlayersTable',
    newPlayersTable: 'newPlayersTable',
    allGamesTable: 'allGamesTableV8',
    gameJerseyTable: 'gameJerseyTableV2',
    allUsers: 'allUsers',
    allSeasons: 'allSeasons',
    allPersonnelTypes: 'allPersonnelTypes',
    allPersonnel: 'allPersonnel',
    allSportHalls: 'allSportHalls',
    allReferees: 'allReferees',
    competitionParts: 'competitionParts',
    pendingInfoTeamsTable: 'pendingInfoTeamsTable',
    gamesRescheduleTable: 'gamesRescheduleTable',
    seasonCalendar: 'seasonCalendarV3',
};
