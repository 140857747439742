<template>
    <div>
        <b-row v-if="title !== null">
            <b-col cols="12">
                {{ title }}
            </b-col>
        </b-row>
        <b-row>
            <b-col v-for="field in fields"
                   v-if="field.show"
                   :key="field.key"
                   :xl="field.xl" :lg="field.lg" :md="field.md" :sm="field.sm">

                <text-input v-if="field.type === 'text'"
                            :ref="field.key"
                            :debounce="420"
                            :disabled="field.disabled"
                            :feedback="field.error"
                            :initial-value="field.value"
                            :label="field.label + (field.required ? '*' : '')"
                            :state="field.error !== null ? false : null"
                            @value-change="changeValue($event, field.key)"/>

                <password-input v-if="field.type === 'password'"
                            :ref="field.key"
                            :debounce="420"
                            :disabled="field.disabled"
                            :feedback="field.error"
                            :initial-value="field.value"
                            :label="field.label + (field.required ? '*' : '')"
                            :state="field.error !== null ? false : null"
                            @value-change="changeValue($event, field.key)"/>

                <text-area-input v-if="field.type === 'textArea'"
                                 :ref="field.key"
                                 :debounce="420"
                                 :disabled="field.disabled"
                                 :feedback="field.error"
                                 :initial-value="field.value"
                                 :label="field.label + (field.required ? '*' : '')"
                                 :state="field.error !== null ? false : null"
                                 @value-change="changeValue($event, field.key)"/>

                <number-input v-if="field.type === 'number'"
                              :ref="field.key"
                              :debounce="420"
                              :disabled="field.disabled"
                              :feedback="field.error"
                              :initial-value="field.value"
                              :label="field.label + (field.required ? '*' : '')"
                              :state="field.error !== null ? false : null"
                              @value-change="changeValue($event, field.key)"/>

                <value-select v-else-if="field.type === 'select'"
                              :ref="field.key"
                              :clearable="field.clearable"
                              :disabled="field.disabled"
                              :do-reduce="field.doReduce"
                              :feedback="field.error"
                              :initial-value="field.value"
                              :label="field.label + (field.required ? '*' : '')"
                              :multiple="field.multiple"
                              :options="field.options"
                              :reduce-by="field.reduceBy"
                              :selectedLabel="field.selectedLabel"
                              :state="(!(field.error !== null))"
                              @value-change="changeValue($event, field.key)"/>

                <date-select v-else-if="field.type === 'date'"
                             :ref="field.key"
                             :feedback="field.error"
                             :initial-value="field.value"
                             :label="field.label + (field.required ? '*' : '')"
                             :state="field.error !== null ? false : null"
                             :disabled="field.disabled"
                             @value-change="changeValue($event, field.key)"/>

                <time-select v-else-if="field.type === 'time'"
                             :ref="field.key"
                             :feedback="field.error"
                             :initial-value="field.value"
                             :label="field.label + (field.required ? '*' : '')"
                             :state="field.error !== null ? false : null"
                             :disabled="field.disabled"
                             @value-change="changeValue($event, field.key)"/>

                <boolean-checkbox v-else-if="field.type === 'boolean'"
                                  :ref="field.key"
                                  :feedback="field.error"
                                  :initial-value="field.value"
                                  :label="field.label + (field.required ? '*' : '')"
                                  :state="field.error !== null ? false : null"
                                  :disabled="field.disabled"
                                  @value-change="changeValue($event, field.key)"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { validate } from '@validations';

export default {
    name: 'BaseForm',
    props: {
        titleProp: {
            type: String,
            default: null,
        },
        fieldsProp: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            title: this.titleProp,
            fields: [
                ...this.fieldsProp.map((f) => {
                    const key = f.hasOwnProperty('key') ? f.key : this.$uuid.v1();
                    return {
                        ...f,
                        key: key,
                        value: f.hasOwnProperty('value') ? f.value : null,
                        validateRules: f.hasOwnProperty('validateRules') ? f.validateRules : null,
                        show: f.hasOwnProperty('show') ? f.show : true,
                        required: f.hasOwnProperty('validateRules') ? f.validateRules.includes('required') : false,
                        label: f.hasOwnProperty('label') ? f.label : this.camelPad(key),
                        error: f.hasOwnProperty('error') ? f.error : null,
                        disabled: f.hasOwnProperty('disabled') && f.disabled,
                        clearable: f.hasOwnProperty('clearable') && f.clearable,
                        multiple: f.hasOwnProperty('multiple') && f.multiple,
                        doReduce: f.hasOwnProperty('doReduce') && f.doReduce,
                        reduceBy: f.hasOwnProperty('reduceBy') ? f.reduceBy : 'id',
                        options: f.hasOwnProperty('options') ? f.options : [],
                        selectedLabel: f.hasOwnProperty('selectedLabel') ? f.selectedLabel : 'name',
                        xl: f.hasOwnProperty('xl') ? f.xl : 12,
                        lg: f.hasOwnProperty('lg') ? f.lg : 12,
                        md: f.hasOwnProperty('md') ? f.md : 12,
                        sm: f.hasOwnProperty('sm') ? f.sm : 12,
                    };
                }),
            ],
        };
    },

    mounted() {
    },

    methods: {
        camelPad(key) {
            return key
                .replace('_', ' ')
                .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
                .replace(/([a-z\d])([A-Z])/g, '$1 $2')
                .replace(/([a-zA-Z])(\d)/g, '$1 $2')
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                })
                .trim();
        },

        async hasError() {
            let error = false;
            for (const field of this.fields) {
                if (field.show) {
                    field.error = await this.getError(field);

                    if (field.error !== null) {
                        error = true;
                        this.$root.$bvToast.toast(field.error, {
                            title: 'Neveljaven vnos',
                            variant: 'danger',
                            solid: true,
                            toaster: 'b-toaster-bottom-right',
                        });
                    }
                }
            }

            return error;
        },

        async getError(field) {
            //TODO check for email, phone... validation rules or implement for vee-validate -> https://vee-validate.logaretm.com/v3/guide/rules.html#importing-the-rules
            if (field.validateRules === null) return null;

            const result = await validate(field.value, field.validateRules, {name: field.label});

            if (result.valid) return null;
            return result.errors.toString();
        },

        async changeValue(value, key) {
            const field = this.fields.find((f) => f.key === key);
            field.value = value;
            if (field.validateRules !== null)
                field.error = await this.getError(field);

            this.$emit('value-change', {'value': field.value, 'key': field.key});
        },
    },
};
</script>
