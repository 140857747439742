import axios from '@/libs/axios';
import {stringCompare} from "@/owms/utils/comperator";

export default {
    namespaced: true,
    state: {
        activeClubs: [],
    },
    getters: {
        async activeClubs(state) {
            if(state.activeClubs.length === 0) {
                try {
                     const clubs = (await axios.get('/auth/clubs/active')).data;

                    state.activeClubs = clubs.sort((c1, c2) => stringCompare(c1.name, c2.name));
                }
                catch (e) {
                    console.log(e);
                    state.activeClubs = [];
                }
            }
            return state.activeClubs;
        },
    },
    mutations: {
    },
    actions: {
        async clubInformation ({}, clubId) {
            try {
                return (await axios.get(`/auth/club-information/${clubId}`)).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async definedContacts ({}, clubId) {
            try {
                return (await axios.get(`/auth/club-information/contacts/${clubId}`)).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async storeInformation ({}, data) {
            try {
                return (await axios.post(`/auth/club-information/update-or-create`, data)).data.data || null;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },
        async confirmInformation ({}, data) {
            try {
                return (await axios.post(`/auth/club-information/confirm/${data.id}`, data)).data.data || null;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },
        async exportPlayers ({}, clubId) {
            const res = await axios.get(`/auth/clubs/${clubId}/players-export`, {responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'igralci.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
}
